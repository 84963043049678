import BaseActions from '../base_actions.js'

export default class DesignActions extends BaseActions {

  constructor(controller, items, context, currentItem){
    super(controller, items, context, currentItem)

    // Initialize from current selection if items is not provided
    if (!this.nodes || !this.dbitems) this.updateItems({
      node:   this.getSelectedItems('node'),
      dbnode: this.getSelectedItems('dbnode')
    }, currentItem)
  }

  editLine() {
    let label = this.item.labels[0]
    let item_id = this.item.value
    this.helperEditLine(label, {
      title: I18n.t('design.actions.edit_line.name'),
      frame_id: `edit-line-${item_id}`,
      frame_url: Routes.edit_line_make_cs_items_path ({
        root_ref_id: document.body.dataset.rootId,
        ref_id: item_id,
        frame: `edit-line-${item_id}`
      })
    })
  }

  // Probably not used
  editItem(context){
    console.warn('Webpack DesignActions: Deprecated action editItem')
    if (this.items.length == 1) {
      let id = this.item.value
      let type = this.item.dataset.type
      this.openForm(this._buildMakeUrl(type, id, 'edit'))
    }
  }

  async deleteItem(context){
    const values = Array.from(this.items, e => e.value)
    await this._deleteItemImplem(context, values)
  }

  copyItem(context){
    let values = Array.from(this.items, e => e.value)
    let count = values.length
    this.runBackgroundAction('POST', this._buildMultiUrl(values, 'copy'))
      .then(resp => this.addPageNotif('info', 'check-circle', I18n.t('make.cs_items.copy.success', {count}), true))
      .catch(err => this.addPageNotif('danger', 'minus-circle', I18n.t('make.cs_items.copy.error', {count})))
  }

  cutItem(context){
    let values = Array.from(this.items, e => e.value)
    let count = values.length
    this.runBackgroundAction('POST', this._buildMultiUrl(values, 'cut'), {}, { 'accept': 'application/json' })
      .then(
        resp => {
          if (resp.status == 401) {
            this.addPageNotif('danger', 'check-circle', I18n.t('front.actions.errors.unauthorized'))
          } else {
            this.addPageNotif('info', 'minus-circle', I18n.t('make.cs_items.cut.success', {count}), true)
          }
        })
      .catch(err => this.addPageNotif('danger', 'minus-circle', I18n.t('front.actions.errors.contact_support')))
  }

  pasteItem({target = null}){
    let values = []
    if (this.items.length == 0) {
      values.push(this.items[0].value)
    } else {
      values.push(this.currentItem.dataset.id)
    }
    let count = values.length
    this.runBackgroundAction('POST', this._buildMultiUrl(values, 'paste'), {}, { 'accept': 'application/json' })
      .then(response => response.json())
      .then(data => {
        if (!data.errors) {
          this.addPageNotif('info', 'check-circle', I18n.t('make.cs_items.paste.success', {count}), true)
        } else {
          this.addPageNotif('danger', 'minus-circle', data.errors)
        }
      })
      .catch(err => this.addPageNotif('danger', 'minus-circle', I18n.t('make.cs_items.paste.error', {count})))
  }

  fixBroken(context) {
    const values = Array.from(this.items, e => e.value)
    this.submitHiddenForm(Routes.multi_fix_broken_make_cs_items_path({
      interface: document.body.dataset.interface,
      parent_ref_id: document.body.dataset.rootId
    }), 'POST', {
      'ref_ids[]': values
    })
  }

  updateModules(context) {
    let values = Array.from(this.items, e => e.value)
    this.submitHiddenForm(Routes.multi_update_version_make_cs_items_path({
      interface: document.body.dataset.interface,
      parent_ref_id: document.body.dataset.rootId
    }), 'POST', {
      'ref_ids[]': values,
    })
  }

  replaceItem(){
    let type = this.node?.dataset.type.replace('Cs::', '').toLowerCase()

    const url = `/make/${_.pluralize(type)}/new_multi_replace?ref_id=${encodeURIComponent(this.node.value)}&new_db_item_ref_id=${encodeURIComponent(this.dbitem?.dataset?.id || '')}`
    this.openForm(url)
  }

  infoDbItem(context){
    let id = this.dbitem.dataset.id
    let type = this.dbitem.dataset.type.replace('Db::', '').toLowerCase()

    this.blankGoTo(`/database/${_.pluralize(type)}/${id}`)
  }

  addDbItem(context){
    this.dbitem.parentElement.querySelector('a').click()
  }

  replaceDbItem(context){
    return this.replaceItem(context)
  }

  compareDbItem(context){
    let root_ref_id = document.body.dataset.rootId || this.rootItem.dataset.refId
    let db_item_id = this.dbitem.dataset.id
    let library = this.dbitem.closest('[data-design-library-comparison-id]')
    let comparison_id = library.getAttribute('data-design-library-comparison-id')

    this.goTo(Routes.add_comparison_comparison_item_path({
      comparison_id:  comparison_id,
      id:             db_item_id,
      cs_item_ref_id: root_ref_id
    }))
  }

  addPhase(context){
    this.openForm(Routes.new_make_cs_phase_path({
      interface: 'design',
      parent_ref_id: this.rootItem.dataset.refId,
      root_ref_id: this.rootItem.dataset.refId
    }))
  }

  addDossier(context){
    this.openForm(this._container_url('dossier'))
  }

  addSubassembly(context){
    this.openForm(this._container_url('subassembly'))
  }

  addPart(context){
    this.openForm(this._container_url('part'))
  }

  _container_url(container_type) {
    return Routes.new_make_cs_container_path({
      interface: 'design',
      parent_ref_id: (this.currentItem.dataset.id || this.currentItem.value),
      container_type: container_type,
      root_ref_id: this.rootItem.dataset.refId
    })
  }

  addTransportStep(context){
    this.openForm(Routes.new_make_cs_transport_step_path({
      interface: 'design',
      root_ref_id: this.rootItem.dataset.refId,
      parent_ref_id: (this.currentItem.dataset.id || this.currentItem.value)
    }))
  }

  addEnergyConsumption(context){
    this.openForm(Routes.new_make_cs_energy_consumption_path({
      interface: 'design',
      root_ref_id: this.rootItem.dataset.refId,
      parent_ref_id: (this.currentItem.dataset.id || this.currentItem.value)
    }))
  }

  // Protected

  updateDbnodeItems(dbnodes, _){
    this.dbitems = dbnodes.filter(n => (!n.dataset.type.match(/^Category/)))
    this.dbitem = this.dbitems.length == 1 ? this.dbitems[0] : null
  }

  updateAction(actname, action) {
    this.disable(action)

    if (this.controller.debug) {
      console.log('%s/updateAction(%s) currentItem = %o', this.controller.identifier, actname, this.currentItem)
    }

    switch (actname) {
    case 'addPhase':
      this.showEnable(action, true, this.currentItem?.dataset?.typeClass == 'Cs::CaseStudy')
      return
    case 'addDossier':
    case 'addSubassembly':
    case 'addPart':
    case 'addTransportStep':
    case 'addEnergyConsumption':
      this.showEnable(action, true, this.currentItem?.dataset?.typeClass != 'Cs::CaseStudy')
      return
    case 'editLine':
    case 'editItem':
    case 'pasteItem':
      if (this.items.length <= 1) this.show(action, true, true, true, true)
      break
    case 'deleteItem':
    case 'copyItem':
    case 'cutItem':
      if (this.items.length >= 1) this.show(action, true, true, true, true)
      break
    case 'replaceItem':
    case 'replaceDbItem':
    case 'compareItem':
    case 'findInLibrary':
      if (this.items.length == 1) this.show(action, true, true, ['module', 'flow'].includes(this.itemType))
      break
    case 'addDbItem':
    case 'infoDbItem':
    case 'compareDbItem':
      if (this.dbitem) this.show(action, true, true, true, true)
      break
    case 'fixBroken': {
      const num_broken = this.items.map(i => i.dataset.numBroken).reduce((res, n) => (res + n), 0)
      const is_broken = num_broken > 0
      this.show(action, true, true, is_broken)
      break
    }
    case 'updateModules':
      this.show(action, true, true, this.items.length >= 1)
      break
    case 'copyRefId':
    }
  }

}
