import ContextMenuBaseController from '../utils/context_menu_base_controller'
import DesignActions from '../../src/design/actions.js'

export default class extends ContextMenuBaseController {
  static targets = ['addPhase', 'addDossier',
    'addSubassembly', 'addPart', 'addEnergy',
    'addTransport', 'paste']

  filterContextMenuClick(e){
    return e.detail.kind === 'container'
  }

  connect(){
    super.connect()

    this.addAction('keydown@document', 'handleShortcuts')

    const actions = new DesignActions(this, [], {toolbar: false, menu: 'current_node'})
    actions.updateActions({
      'pasteItem': this.pasteTarget,
      'addPhase': this.addPhaseTarget,
      'addDossier': this.addDossierTarget,
      'addSubassembly': this.addSubassemblyTarget,
      'addPart': this.addPartTarget,
      'addTransportStep': this.hasAddTransportTarget ? this.addTransportTarget : null,
      'addEnergyConsumption': this.hasAddEnergyTarget ? this.addEnergyTarget : null
    })

    this.bindActions(actions)
  }

  updateActions(){
  }

  handleShortcuts(e){
    if (this.isInputKeydown(e)) return
    this.updateActions()

    if (e.key == 'h' && !e.ctrlKey && !e.shiftKey && !e.metaKey && !e.altKey) {
      return this._activateMenuItem(e, this.addPhaseTarget)
    }

    if (e.key == 'n' && !e.ctrlKey && !e.shiftKey && !e.metaKey && !e.altKey) {
      return this._activateMenuItem(e, this.addDossierTarget)
    }

    if (e.key == 's' && !e.ctrlKey && !e.shiftKey && !e.metaKey && !e.altKey) {
      return this._activateMenuItem(e, this.addSubassemblyTarget)
    }

    if (e.key == 'p' && !e.ctrlKey && !e.shiftKey && !e.metaKey && !e.altKey) {
      return this._activateMenuItem(e, this.addPartTarget)
    }

    if (e.key == 't' && !e.ctrlKey && !e.shiftKey && !e.metaKey && !e.altKey) {
      return this._activateMenuItem(e, this.addTransportTarget)
    }

    if (e.key == 'c' && !e.ctrlKey && !e.shiftKey && !e.metaKey && !e.altKey) {
      return this._activateMenuItem(e, this.addEnergyTarget)
    }

    if (e.key == 'V' && !e.ctrlKey && e.shiftKey && !e.metaKey && !e.altKey) {
      return this._activateMenuItem(e, this.pasteTarget)
    }

    return false
  }

  getRootItem(){
    let interfaceController = this.getController('interface')

    return JSON.parse(interfaceController.data.get('root-item'))
  }
}