import ApplicationController from '../application_controller'

export default class DesignToolbar extends ApplicationController {
  connect(){
    super.connect()
  }

  openFormPopup(e){
    e.preventDefault()
    this.openMakeForm(e.target.closest('a').href, (form) => {})
  }
}
